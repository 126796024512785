import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Image from './Image';

// import { pxToRem } from '../theme';

const styles = theme =>
  createStyles({
    root: {},
    image: {
      height: '65vh',
      overflow: 'hidden',
    },
    title: {
      padding: 40,
      backgroundColor: '#E9E9E9',
    },
    copy: {
      padding: 40,
      backgroundColor: theme.palette.common.white,
    },
    titleWidth: {
      [theme.breakpoints.up(960)]: {
        maxWidth: 960,
        margin: '0 auto',
      },
    },
    textWidth: {
      [theme.breakpoints.up(960)]: {
        maxWidth: 600,
        margin: '0 auto',
      },
    }
  });

const AboutSection = ({ classes, bodyCopy, titleCopy, image }) => (
  <Grid container direction="column" className={classes.root}>
    <Grid item className={classes.image}>
      <Image relativePath={image.src} className={image.className} />
    </Grid>
    <Grid item className={classes.title}>
      <Typography
        className={classes.titleWidth}
        variant="h2" color="primary">
        {titleCopy}
      </Typography>
    </Grid>
    <Grid item className={classes.copy}>
      <Typography
        className={classes.textWidth}
        variant="body1" style={{ textAlign: 'center' }}>
        {bodyCopy}
      </Typography>
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(AboutSection);
