import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';

import { AboutPage } from '../imageURLs';

import SEO from '../components/SEO';

import AboutSection from '../components/AboutSection';
import CtaBlock from '../components/CtaBlock';
import { UiButton } from '../components/CTAButton/CTAButton';

import { withQuoteWizard } from '../components/withQuoteWizard';

const styles = theme =>
  createStyles({
    root: {},
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  });

class AboutUs extends React.Component {
  render() {
    const { classes, toggleQuoteComp, children } = this.props;

    return (
      <React.Fragment>
        <SEO
          title="About Twin View Turf - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <AboutSection
          image={{ className: classes.img, src: AboutPage.section1 }}
          titleCopy="We are a team of 40+ turf experts"
          bodyCopy="We are an experienced team of 40+ turf experts with qualifications ranging from horticulture to farm management."
        />
        <AboutSection
          image={{ className: classes.img, src: AboutPage.section2}}
          titleCopy="50 years farming 600 acres of land"
          bodyCopy="From humble beginnings as a tobacco farm in 1965, we now have over 600 acres of turf in production at any given time."
        />
        <AboutSection
          image={{ className: classes.img, src: AboutPage.section3}}
          titleCopy="Premium fleet of 17 trucks"
          bodyCopy="From our trucks to our machinery, we have over 200 individual items that ensure every blade of grass is nothing but perfect."
        />
        <AboutSection
          image={{ className: classes.img, src: AboutPage.section4}}
          titleCopy="Industry leading technology"
          bodyCopy="We utilise the latest technology across all areas of our business to ensure each process results in quality outcomes."
        />
        <AboutSection
          image={{ className: classes.img, src: AboutPage.section5}}
          titleCopy="State-of-the-art irrigators"
          bodyCopy="Our paddocks are watered with eight digitally controlled irrigators to ensure that your new lawn doesn't miss a drop."
        />
        <AboutSection
          image={{ className: classes.img, src: AboutPage.section6}}
          titleCopy="LSA Accredited Grower"
          bodyCopy="Twin View Turf are an AusGap Certified LSA Accredited Grower."
        />
        <CtaBlock title="Discover the TVT difference">
          <UiButton size="large" btnType="primary" onClick={toggleQuoteComp}>
            Get quote
          </UiButton>
        </CtaBlock>
        {children}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(AboutUs)
);
